@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './divider-config';

.ace-c-divider {
    width: 100%;
    height: 1px;
    background-color: map-get($divider-config, divider-background-color);
    border: 0;
    box-sizing: border-box;
}
