@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './navigation-bar-config';

.ace-c-navigation-bar {
    height: map-get($navigation-bar-config, navigation-bar-height);
    background-color: map-get($navigation-bar-config, navigation-bar-background);
    box-shadow: map-get($navigation-bar-config, navigation-bar-shadow);
    z-index: map-get($navigation-bar-config, navigation-bar-z-index);

    &--is-sticky {
        position: sticky;
        top: map-get($navigation-bar-config, navigation-bar-top-sticky);
        z-index: map-get($navigation-bar-config, navigation-bar-z-index-sticky);
    }
}

.ace-c-navigation-bar__content {
    max-width: map-get($navigation-bar-config, content-max-width);
    min-width: map-get($navigation-bar-config, content-min-width);
    height: 100%;

    @include media('>=x-large') {
        margin: 0 auto;
    }
}
