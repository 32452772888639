@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';

.ace-c-brick {
    display: inline-block;
    padding: map-get($sizes, ace-4);
    border: 1px solid map-get($color-palette, ace-red-800);
    background-color: map-get($color-palette, ace-red-300);
}
