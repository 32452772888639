@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';

$drop-down-config: (
    drop-down-spacing: 0,
    drop-down-background: map-get($base-colors, background-main),
    drop-down-border-radius: 0,
    drop-down-shadow: map-get($shadows, shadow-drop-down),
);

.ace-c-drop-down {
    padding: map-get($drop-down-config, drop-down-spacing) 0;
}

.ace-c-drop-down__content {
    overflow: hidden;
    background: map-get($drop-down-config, drop-down-background);
    border-radius: map-get($drop-down-config, drop-down-border-radius);
    box-shadow: map-get($drop-down-config, drop-down-shadow);
}
