@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';

$app-layout-config: (
    content-min-width: map-get($breakpoints, xsmall),
    content-max-width: map-get($breakpoints, large),
    content-padding: map-get($spacing, 128),
    background: map-get($base-colors, background-b),
    header-bar-background: map-get($base-colors, background-c),
    header-bar-z-index: map-get($z-index, navigation),
    footer-bar-height: 48px,
);

.ace-c-app-layout {
    position: relative;
    padding: 0;
    display: grid;
    grid-template:
        'bar-1' auto
        'bar-2' auto
        'bar-3' auto
        'bar-4' auto
        'bar-5' auto
        'main' 1fr
        'header' auto
        'footer' auto;
    grid-template-columns: 100%;
    min-height: 100%;
    background-color: map-get($app-layout-config, background);
}

.ace-c-app-layout__header {
    grid-area: header;
}

.ace-c-app-layout__main {
    grid-area: main;
}

.ace-c-app-layout__main-content {
    max-width: map-get($app-layout-config, content-max-width);
    min-width: map-get($app-layout-config, content-min-width);
    min-height: calc(100vh - 56px);
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding: 30px 16px;

    @include media('>=medium') {
        padding: 64px 128px;
        min-height: calc(100vh - 76px);
    }
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__bar-1 {
    grid-area: bar-1;
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__bar-2 {
    grid-area: bar-2;
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__bar-3 {
    grid-area: bar-3;
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__bar-4 {
    grid-area: bar-4;
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__bar-5 {
    grid-area: bar-5;
}

// placeholder element for postcss grid-area validator
.ace-c-app-layout__footer {
    grid-area: footer;
}

.ace-c-app-layout__footer-content {
    max-width: map-get($app-layout-config, content-max-width);
    min-width: map-get($app-layout-config, content-min-width);
    height: map-get($app-layout-config, footer-bar-height);
    position: relative;
    margin: 0 auto;
    padding: 0 16px;
}
