@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './radio-tile-config';
@import './RadioButton.module';

$background-variants: (
    negative,
    positive,
    primary,
);

@include state-styles.configure('ace-c-radio-tile', $radio-tile-config);

.ace-c-radio-tile__wrap {
    padding: 16px 8px;
    display: inline-flex;
    flex-grow: 1;
    flex-direction: map-get($radio-tile-config, radio-tile-wrap-direction);
    align-items: center;
    min-height: map-get($radio-tile-config, radio-tile-wrap-min-height);
    min-width: 144px;
    max-width: 100%;
    height: 100%;
    border: map-get($radio-tile-config, radio-tile-wrap-border);
    background-color: map-get($radio-tile-config, radio-tile-wrap-background);
    text-align: map-get($radio-tile-config, radio-tile-wrap-text-align);

    &:not(#{&}--is-positive):not(#{&}--is-negative) {
        @include state-styles.element() using($get-design-token) {
            border: meta.call($get-design-token, radio-tile-wrap-border);
            background-color: meta.call($get-design-token, radio-tile-wrap-background);

            .ace-c-radio-tile__icon > svg {
                fill: meta.call($get-design-token, radio-tile-icon);
            }
        }
    }

    &--is-positive#{&}--is-selected {
        border: map-get($radio-tile-config, radio-tile-wrap-border-selected-positive);
        background-color: map-get($radio-tile-config, radio-tile-wrap-background-selected-positive);

        .ace-c-radio-tile__icon {
            svg {
                fill: map-get($radio-tile-config, radio-tile-icon-selected-positive);
            }
        }
    }

    &--is-negative#{&}--is-selected {
        border: map-get($radio-tile-config, radio-tile-wrap-border-selected-negative);
        background-color: map-get($radio-tile-config, radio-tile-wrap-background-selected-negative);

        .ace-c-radio-tile__icon {
            svg {
                fill: map-get($radio-tile-config, radio-tile-icon-selected-negative);
            }
        }
    }

    &:focus {
        border: map-get($radio-tile-config, radio-tile-border-focus);
        box-shadow: map-get($radio-tile-config, radio-tile-shadow-focus);
        outline: 0;
    }

    @include media('>small') {
        @include state-styles.element(
            $not-for-states: (disabled, selected-disabled),
        ) using($get-design-token) {
            border: meta.call($get-design-token, radio-tile-wrap-border);
            background-color: meta.call($get-design-token, radio-tile-wrap-background);

            .ace-c-radio-tile__text {
                color: meta.call($get-design-token, radio-tile-text-color);
            }

            .ace-c-radio-tile__meta {
                color: meta.call($get-design-token, radio-tile-text-color);
            }
        }
    }

    @include media('<=small') {
        @include state-styles.element(
            $not-for-states: (disabled, selected-disabled, hover, active),
        ) using($get-design-token) {
            border: meta.call($get-design-token, radio-tile-wrap-border);
            background-color: meta.call($get-design-token, radio-tile-wrap-background);

            .ace-c-radio-tile__text {
                color: meta.call($get-design-token, radio-tile-text-color);
            }

            .ace-c-radio-tile__meta {
                color: meta.call($get-design-token, radio-tile-text-color);
            }
        }
    }
}

.ace-c-radio-tile__icon {
    margin-bottom: map-get($radio-tile-config, radio-tile-icon-margin-bottom);
}

.ace-c-radio-tile__title {
    @include typography($typography-styles, map-get($radio-tile-config, radio-tile-title-typography));

    color: map-get($radio-tile-config, radio-tile-title-color);
}

.ace-c-radio-tile__text {
    @include typography($typography-styles, map-get($radio-tile-config, radio-tile-text-typography));

    color: map-get($radio-tile-config, radio-tile-text-color);
}

.ace-c-radio-tile__meta {
    @include typography($typography-styles, map-get($radio-tile-config, radio-tile-text-typography));

    color: map-get($radio-tile-config, radio-tile-text-color);
    align-self: start;
}

.ace-c-radio-tile--category-tile {
    .ace-c-radio-tile__wrap {
        display: block;
        align-self: stretch;
    }
}
