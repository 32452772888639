@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';
@import '../functions';
@import '../mixins';
@import './button-config';

.ace-c-button {
    @include typography($typography-styles, map-get($button-config, button-text-typography));

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0 map-get($sizes, ace-24);
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    min-width: 100px;
    border: map-get($button-config, button-border);
    background: map-get($button-config, button-background);
    color: map-get($button-config, button-text-color);
    box-shadow: map-get($button-config, button-shadow);

    &:focus {
        color: map-get($button-config, button-text-color-focus);
        background: map-get($button-config, button-background-focus);
        border: map-get($button-config, button-border-focus);
        box-shadow: map-get($button-config, button-shadow-focus);
        outline: 0;
    }

    &--is-disabled {
        color: map-get($button-config, button-text-color-disabled);
        background: map-get($button-config, button-background-disabled);
        border: map-get($button-config, button-border-disabled);
        box-shadow: map-get($button-config, button-shadow-disabled);
        cursor: default;
    }

    &:hover:not(#{&}--is-disabled):not(:focus) {
        color: map-get($button-config, button-text-color-hover);
        background: map-get($button-config, button-background-hover);
        border: map-get($button-config, button-border-hover);
        box-shadow: map-get($button-config, button-shadow-hover);
    }

    &:active:not(#{&}--is-disabled) {
        color: map-get($button-config, button-text-color-active);
        background: map-get($button-config, button-background-active);
        border: map-get($button-config, button-border-active);
        box-shadow: map-get($button-config, button-shadow-active);
    }

    &--no-chrome {
        border-radius: 0;
        border: none;
        background-color: transparent;
        padding: 0;
    }

    &--no-chrome,
    &--no-chrome#{&}--is-disabled,
    &--no-chrome#{&}:hover:not(#{&}--is-disabled) {
        border-radius: 0;
        border: none;
        background-color: transparent;
        padding: 0;
        min-width: auto;
    }
}
