@use 'sass:meta';
@use '../state-styles';

@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';

@import '../config';
@import '../functions';
@import '../mixins';
@import './toggle-tab-config';
@import './Checkbox.module';

@include state-styles.configure('ace-c-toggle-tab-switch', $toggle-tab-config);

.ace-c-toggle-tab-switch__track {
    padding: map-get($toggle-tab-config, toggle-tab-track-padding);
    width: map-get($toggle-tab-config, toggle-tab-track-width);
    height: map-get($toggle-tab-config, toggle-tab-track-height);
    background-color: map-get($toggle-tab-config, toggle-tab-track-background);
    border-radius: map-get($toggle-tab-config, toggle-tab-track-border-radius);
    display: flex;
    align-items: center;

    &:focus {
        border: map-get($toggle-tab-config, toggle-tab-border-focus);
        box-shadow: map-get($toggle-tab-config, toggle-tab-shadow-focus);
        outline: 0;
    }
}

.ace-c-toggle-tab-switch__tab {
    @include typography($typography-styles, map-get($toggle-tab-config, toggle-tab-typography));

    width: map-get($toggle-tab-config, toggle-tab-width);
    height: map-get($toggle-tab-config, toggle-tab-height);
    border-radius: map-get($toggle-tab-config, toggle-tab-border-radius);
    color: map-get($toggle-tab-config, toggle-tab-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include state-styles.element() using($get-design-token) {
        color: meta.call($get-design-token, toggle-tab-color);
        background-color: meta.call($get-design-token, toggle-tab-background);
        cursor: meta.call($get-design-token, toggle-tab-cursor);
    }
}
