@import '/home/jenkins/agent/workspace/DEV/mia-webapp/src/theme-config.scss';
@import '../config';

$circle-config: (
    circle-width: map-get($dimensions, spacing-128),
    circle-height: map-get($dimensions, spacing-128),
    circle-background-color: map-get($base-colors, highlight-alpha-10),
    circle-background-color-warning: map-get($base-colors, warning-10),
    circle-background-color-success: map-get($base-colors, success-10),
    circle-breakpoint-small: map-get($breakpoints, small)
);

.ace-c-circle {
    width: map-get($circle-config, circle-width);
    height: map-get($circle-config, circle-height);
    border-radius: 50%;
    background: map-get($circle-config, circle-background-color);
    display: flex;
    align-items: center;
    justify-content: center;

    &--small {
        width: 80px;
        height: 80px;
    }

    &--medium {
        width: 112px;
        height: 112px;
    }

    &--medium-responsive {
        width: 112px;
        height: 112px;

        @include media('<=#{map-get($circle-config, circle-breakpoint-small)}') {
            width: 80px;
            height: 80px;
        }
    }

    &--is-warning {
        background: map-get($circle-config, circle-background-color-warning);
    }

    &--is-success {
        background: map-get($circle-config, circle-background-color-success);
    }
}
